import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  DialogContent,
  TextField,
  LinearProgress,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  API_clone_bookings,
  API_delete_bookings_id,
  API_edit_booking,
  API_get_bookings,
} from "./utils/http";

import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { DMY_to_YMD, idToTimeYYYYMMD } from "./utils/tool";
import FilterBooking from "./components/FilterBooking";
import {
  COLUMN_BOOKING,
  STATUS_CODE,
  TOKEN_USERNAME_APP,
} from "./utils/constDispatch";
import { AlertCus } from "./components/AlertCus";
import AppBarSearchBox from "./components/SearchBox";
import { DialogCus } from "./components/DialogCus";
import InputLeft from "./InputLeft";
import OutputRight from "./components/pdf/OutputRight";

function ListBooking() {
  const [data, set_data] = useState(null);
  const [data_filter, set_data_filter] = useState(null);
  const [search_text, set_search_text] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open_details, setOpen_details] = useState(false);
  const [new_bookings, setNewBookings] = useState("");

  const [clone_ok, set_clone_ok] = useState(null);
  const [delete_ok, set_delete_ok] = useState(null);
  const [delete_status, set_delete_status] = useState(null);

  const [api_status, set_api_status] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_status = Boolean(anchorEl);
  const [density, setDensity] = React.useState("compact");

  const [filter_list, set_filter_list] = useState({
    mybooking: true,
    proceeding: true,
    dealing: true,
    sample: false,
    done: false,
    date_from: null,
    date_to: null,
    all: false,
  });

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClickOpen = () => setOpen(!open);
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };

  const get_data_booking = async () => {
    await API_get_bookings((x) => {
      if (x.success) {
        let data = _.orderBy(x.data, "_id", "desc");

        let x_new = [];
        data.map((item) => {
          item.created_at = idToTimeYYYYMMD(item._id);
          if (item.day_start != null) {
            item.day_start = DMY_to_YMD(item.day_start);
          }
          x_new.push(item);
        });
        set_data(x_new);

        x_new = apply_filters(x_new, filter_list);
        set_data_filter(x_new);
      }
    });
  };

  const confirmDelete = () => {
    handleClickOpen1();
    API_delete_bookings_id(selected_id, (x) => {
      set_delete_ok(x.success);
      if (x.success) {
        get_data_booking();
      } else {
        set_delete_status(x.message);
      }
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(data);
    } else {
      let data_tmp = data.filter((x) => {
        let str =
          x.booking_number +
          x.customer_name +
          x.customer_email +
          x.customer_cell +
          x.package +
          x.created_by +
          x.created_at;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const change_status_booking = async (status) => {
    let body = { status: status };

    await API_edit_booking(selected_id, body, (x) => {
      if (x.success) {
        get_data_booking();
      }
    });
  };

  const MenuStatus = (status_str) => {
    return (
      <MenuItem
        onClick={(e) => {
          handleClose(e);
          change_status_booking(status_str);
        }}
      >
        {status_str}
      </MenuItem>
    );
  };

  const apply_filters = (data, x_filter) => {
    if (x_filter.all) {
      return data;
    }

    let data_tmp = data.filter((x) => {
      if (x_filter.proceeding && x.status == STATUS_CODE.proceeding) {
        return true;
      } else if (x_filter.dealing && x.status == STATUS_CODE.dealing) {
        return true;
      } else if (x_filter.sample && x.status == STATUS_CODE.sample) {
        return true;
      } else if (x_filter.done && x.status == STATUS_CODE.done) {
        return true;
      } else if (
        !x_filter.proceeding &&
        !x_filter.dealing &&
        !x_filter.sample &&
        !x_filter.done &&
        x.status == null
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (x_filter.mybooking) {
      data_tmp = data_tmp.filter((x) => {
        return x.created_by == sessionStorage.getItem(TOKEN_USERNAME_APP);
      });
    }

    if (x_filter.date_from != null) {
      data_tmp = data_tmp.filter((x) => {
        return x_filter.date_from < dayjs(x.day_start, "DD/MM/YYYY");
      });
    }

    if (x_filter.date_to != null) {
      data_tmp = data_tmp.filter((x) => {
        return dayjs(x.day_start, "DD/MM/YYYY") < x_filter.date_to;
      });
    }
    return data_tmp;
  };

  const onChangeFilter = (x_filter) => {
    let data_tmp = apply_filters(data, x_filter);
    set_data_filter(data_tmp);
  };

  const onClickClone = () => {
    handleClickOpen();
    API_clone_bookings(selected_id, new_bookings, (e) => {
      console.log(e);
      set_clone_ok(e.success);
      if (e.success) {
        get_data_booking();
      } else {
        set_api_status(e.message);
      }
    });
  };

  if (open_details) {
    return (
      <Box>
        <Box style={{ display: "flex", flex: 1 }}>
          <Box style={{ display: "flex", flex: 3 }}>
            <InputLeft
              booking_id={selected_id}
              onExitClick={() => {
                setOpen_details(false);
                get_data_booking();
              }}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box style={{ display: "flex", flex: 3 }}>
            <OutputRight booking_id={selected_id} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <AlertCus condition={clone_ok} message="Clone" error_text={api_status} />
      <AlertCus
        condition={delete_ok}
        message="delete"
        error_text={delete_status}
      />
      <AppBarSearchBox
        title="Booking"
        value={search_text}
        onChange={(e) => {
          if (e.target.value == "") {
            handle_search("");
            set_selected_id(null);
          }
          set_search_text(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode == 13) {
            handle_search(search_text);
            set_selected_id(null);
          }
        }}
      />

      {data_filter != null ? (
        <Box style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              padding: 20,
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              endIcon={<CopyAllRoundedIcon />}
              onClick={() => get_data_booking()}
            >
              Refesh
            </Button>

            <Button
              disabled={selected_id == null}
              style={{ marginLeft: 20 }}
              variant="outlined"
              disableElevation
              endIcon={<BorderColorRoundedIcon />}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              Status
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open_status}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {MenuStatus(STATUS_CODE.proceeding)}
              {MenuStatus(STATUS_CODE.dealing)}
              {/* {MenuStatus(STATUS_CODE.sample)} */}
              {MenuStatus(STATUS_CODE.failed)}
            </Menu>

            <Button
              style={{ marginLeft: 20 }}
              disabled={selected_id == null}
              variant="outlined"
              endIcon={<CopyAllRoundedIcon />}
              onClick={() => handleClickOpen()}
            >
              Clone
            </Button>
            <Button
              variant="outlined"
              disabled={selected_id == null}
              color="error"
              style={{ marginLeft: 20 }}
              endIcon={<DeleteRoundedIcon />}
              onClick={() => handleClickOpen1()}
            >
              Delete
            </Button>
          </Box>

          <FilterBooking
            filter_list={filter_list}
            set_filter_list={(e) => {
              set_filter_list(e);
              onChangeFilter(e);
            }}
          />

          <DialogCus
            open={open1}
            title="Confirm delete"
            handleClickOpen={handleClickOpen1}
            handleClickConfirm={confirmDelete}
          ></DialogCus>

          <DialogCus
            open={open}
            title="Clone"
            handleClickOpen={handleClickOpen}
            handleClickConfirm={onClickClone}
          >
            <DialogContent>
              <TextField
                autoFocus
                value={new_bookings}
                onChange={(e) => setNewBookings(e.target.value)}
                margin="dense"
                id="name"
                label="booking number"
                fullWidth
                variant="standard"
              />
            </DialogContent>
          </DialogCus>
          <Box sx={{ height: "50%", width: "100%" }}>
            <DataGrid
              getRowId={(item) => item._id}
              rows={data_filter}
              columns={COLUMN_BOOKING}
              initialState={{
                pagination: { paginationModel: { pageSize: 30 } },
              }}
              pageSizeOptions={[5, 10, 30, 50]}
              rowSelectionModel={rowSelection}
              onRowSelectionModelChange={(e) => {
                setRowSelection(e);
                set_selected_id(e[0]);
              }}
              density={density}
              onDensityChange={(newDensity) => {
                setDensity(newDensity);
              }}
              onCellDoubleClick={() => setOpen_details(true)}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 10 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}

export default ListBooking;
