import dayjs from "dayjs";
import {
  TOKEN_RATE_AUD,
  TOKEN_RATE_USD,
  TOKEN_ROLE_APP,
} from "./constDispatch";

const showRole = (role) => {
  if (role === 100) {
    return "ADMIN";
  } else if (role === 70) {
    return "OPERATOR";
  } else if (role === 50) {
    return "USER";
  }
};

const idToTimeDMMMYYYY = (id) => {
  let date_item = new Date(parseInt(id.substr(0, 8), 16) * 1000);
  date_item = dayjs(date_item).format("D MMM YYYY");

  return date_item;
};

const idToTimeYYYYMMD = (id) => {
  let date_item = new Date(parseInt(id.substr(0, 8), 16) * 1000);
  date_item = dayjs(date_item).format("YYYY/MM/DD");

  return date_item;
};

const DMY_to_YMD = (x) => {
  x = dayjs(x, "DD/MM/YYYY").format("YYYY/MM/DD");
  return x;
};

const checkAdminRole = () => {
  return sessionStorage.getItem(TOKEN_ROLE_APP) == 100;
};

const checkOperatorRole = () => {
  return sessionStorage.getItem(TOKEN_ROLE_APP) == 70;
};

const checkOper_or_admin = () => {
  let role = sessionStorage.getItem(TOKEN_ROLE_APP);

  return role == 100 || role == 70;
};

const makeid = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const render_number = (a) => {
  return Number(a)
    .toFixed(1)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(".0", "");
};

const render_vnd_to_other = (a, rate) => {
  a = a / rate;

  a = new Intl.NumberFormat("en-AU", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(a);

  return a;
};

const render_vnd_to_aud = (a) => {
  a = a * 1000;
  a = render_vnd_to_other(a, sessionStorage.getItem(TOKEN_RATE_AUD));
  return a;
};

const render_vnd_to_usd = (a) => {
  a = a * 1000;

  a = render_vnd_to_other(a, sessionStorage.getItem(TOKEN_RATE_USD));
  return a;
};

const render_number_onchange = (a) => {
  a = a.replaceAll(/,/g, "");
  a = Number(a)
    .toFixed(1)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(".0", "");

  return a;
};

export {
  showRole,
  idToTimeDMMMYYYY,
  idToTimeYYYYMMD,
  DMY_to_YMD,
  checkAdminRole,
  makeid,
  checkOper_or_admin,
  checkOperatorRole,
  render_number,
  render_number_onchange,
  // render_vnd_to_other,
  render_vnd_to_aud,
  render_vnd_to_usd,
};
