import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import _ from "lodash";
import dayjs from "dayjs";
import EditorCus from "../Editor/Editor";
import { checkOper_or_admin, render_number } from "../../utils/tool";
import { DialogCus } from "../DialogCus";
import EditOper from "./EditOper";

export const VendorItem = ({
  item,
  index,
  booking_details,
  vendor_details,
  DeleteClick,
  ConfirmClick,
  EditOperClick,
  SortByDay,
}) => {
  let status = "unconfirmed";
  let status_count = 0;
  let status_color = "#FFEBEE";

  for (let index = 0; index < item.data.length; index++) {
    if (item.data[index].confirm == true) {
      status_count += 1;
    }
  }

  if (status_count == item.data.length) {
    status = "confirmed";
    status_color = "#E1F5FE";
  } else if (status_count < item.data.length && status_count > 0) {
    status = "data changed, confirm again";
    status_color = "#ECEFF1";
  }

  const [open, setOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [dataEdit, setDataEdit] = useState(null);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Box
      style={{
        padding: 10,
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "#B0BEC5",
      }}
    >
      <DialogCus
        title="Edit operation"
        handleClickOpen={handleClickOpenEdit}
        open={openEdit}
      >
        <EditOper
          data_input={dataEdit}
          onSaveClick={(data) => {
            EditOperClick(data);
            handleClickOpenEdit();
          }}
          DeleteClick={() => {
            handleClickOpenEdit();
            DeleteClick(dataEdit._id);
          }}
        />
      </DialogCus>
      <Box
        style={{
          flexDirection: "row",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {SortByDay ? (
            <Typography variant="h5" component="div">
              Day {item.day_index + 1}
              {/* {item.day_index_detail} */}
            </Typography>
          ) : (
            <Typography variant="h5" component="div">
              {item.vendor_type}: {item.vendor_code}
            </Typography>
          )}
          {/* <Box style={{ backgroundColor: status_color }}>
            <Typography>Status: {status}</Typography>
          </Box> */}
        </Box>

        {checkOper_or_admin() && !SortByDay ? (
          <Button
            style={{ margin: 6 }}
            disabled={status == "confirmed"}
            variant="outlined"
            onClick={() => ConfirmClick(item.booking_id, item.vendor_id)}
          >
            Confirm
          </Button>
        ) : null}
      </Box>
      {item.data.map((item1, index1) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: item1.confirm ? "#E1F5FE" : "#FFEBEE",
              padding: 1,
              borderStyle: "solid",
              borderWidth: "thin",
              borderColor: "#B0BEC5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              {SortByDay ? (
                <Typography variant="h6">
                  {item1.vendor_type}
                  {" - "}
                  {item1.vendor_code}
                  {" - "}Service: {item1.service_code}
                </Typography>
              ) : (
                <Typography variant="h6">
                  Day {item1.day_index + 1}
                  {" - "}
                  {/* {item1.day_index_detail}
                  {" - "} */}
                  Service: {item1.service_code}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  // width: "150%",
                }}
              >
                <Typography>
                  double: {render_number(item1.price)} {item1.price_currency}
                </Typography>
                <Typography>
                  single: {render_number(item1.price_for_customer)}{" "}
                  {item1.price_for_customer_currency}
                </Typography>
              </Box>
            </Box>
            {/* {checkOper_or_admin() ? (
              <Button
                size="small"
                onClick={() => {
                  handleClickOpenEdit();
                  setDataEdit(item1);
                }}
              >
                Edit
              </Button>
            ) : null} */}
          </Box>
        );
      })}

      {item.vendor_type == "flight" ? null : SortByDay ? null : (
        <Box>
          <Button
            onClick={() => {
              handleClickOpen();
            }}
            style={{ margin: 6 }}
            variant="outlined"
          >
            View Email
          </Button>
          {open ? (
            <EditorCus
              operation_details={item}
              booking_details={booking_details}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};
