import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  COLUMN_ACCOM,
  COLUMN_ACCOM_MINI,
  COLUMN_ITINERRARY,
  COLUMN_ITINERRARY_MINI,
  COLUMN_VENDOR_SERVICE,
} from "../utils/constDispatch";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  API_create_vendor,
  API_delete_vendors_id,
  API_edit_vendor,
  API_get_vendor,
  API_get_vendor_att,
} from "../utils/http";

import _ from "lodash";
import { render_number, render_number_onchange } from "../utils/tool";
import PriceCurrency from "./operating/PriceCurrency";

export default function VendorService(props) {
  let props_activ = props.activ;
  let props_type_activ = true;
  if (props.vendorType == "hotel" || props.vendorType == "cruise") {
    props_activ = props.accom;
    props_type_activ = false;
  }

  const [id_service_click, set_id_service_click] = useState(null);
  const [id_service_code, set_id_service_code] = useState(null);
  const [id_service_price, set_id_service_price] = useState(null);
  const [id_service_price_curr, set_id_service_price_curr] = useState(null);

  const [id_service_price_for_cus, set_id_service_price_for_cus] =
    useState(null);
  const [id_service_price_for_cus_curr, set_id_service_price_for_cus_curr] =
    useState(null);

  const [new_code, set_new_code] = useState(null);
  const [new_price, set_new_price] = useState(null);
  const [new_price_curr, set_new_price_curr] = useState(null);

  const [new_price_for_cus, set_new_price_for_cus] = useState(null);
  const [new_price_for_cus_curr, set_new_price_for_cus_curr] = useState(null);

  const [search_text, set_search_text] = useState("");
  const [rowSelection_not_contain, set_rowSelection_not_contain] = useState([]);
  const [rowSelection_contain, set_rowSelection_contain] = useState([]);

  const [data_filter, set_data_filter] = useState([]);
  const [list_att, set_list_att] = useState([]);
  const [list_or_add, set_list_or_add] = useState("list");

  const handle_search = (search_text) => {
    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(props_activ);
    } else {
      let data_tmp = props_activ.filter((x) => {
        let str = "";
        if (props_type_activ) {
          str = x.label + x.activities;
        } else {
          str = x.code;
        }

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  function clickService(item) {
    set_id_service_click(item._id);
    set_id_service_code(item.code);

    set_id_service_price(render_number(item.price));
    set_id_service_price_curr(item.price_currency);
    set_id_service_price_for_cus(render_number(item.price_for_customer));
    set_id_service_price_for_cus_curr(item.price_for_customer_currency);

    set_rowSelection_not_contain([]);

    set_data_filter(props_activ);

    let list_att_new = _.filter(props_activ, (v) =>
      _.includes(item.attach_acti, v._id)
    );

    set_list_att(list_att_new);
  }

  function deleteService() {
    API_delete_vendors_id(id_service_click, (data) => {
      if (data.success) {
        props.get_data_vendor_att();
      }
    });
  }

  function saveService() {
    let list_att_new = [];

    list_att.forEach((element) => {
      list_att_new.push(element._id);
    });

    API_edit_vendor(
      id_service_click,
      {
        code: id_service_code,
        price: Number(id_service_price.replaceAll(/,/g, "")),
        price_currency: id_service_price_curr,
        price_for_customer: Number(
          id_service_price_for_cus.replaceAll(/,/g, "")
        ),
        price_for_customer_currency: id_service_price_for_cus_curr,
        attach_acti: list_att_new,
      },
      (data) => {
        if (data.success) {
          props.get_data_vendor_att();
        }
      }
    );
  }

  function AddService() {
    console.log(props.vendorGroupID);
    API_create_vendor(
      {
        code: new_code,
        price: Number(new_price.replaceAll(/,/g, "")),
        price_currency: new_price_curr,
        price_for_customer: Number(new_price_for_cus.replaceAll(/,/g, "")),
        price_for_customer_currency: new_price_for_cus_curr,
        attach_acti: [],
        vendorgroup_id: props.vendorGroupID,
      },
      (data) => {
        if (data.success) {
          props.get_data_vendor_att();
          set_list_or_add("list");
        }
      }
    );
  }

  function AddServiceBtn(arr_add) {
    let x = list_att.concat(
      _.filter(props_activ, (v) => _.includes(arr_add, v._id))
    );

    let x_unique = _.uniqBy(x, function (e) {
      return e._id;
    });

    set_list_att(x_unique);
  }

  function RemoveService(id) {
    let x = [];
    list_att.forEach((e) => {
      if (e._id != id) {
        x.push(e);
      }
    });
    set_list_att(x);
  }

  return (
    <Box style={{ flexDirection: "column", display: "flex", padding: 10 }}>
      <Button
        onClick={() => {
          set_list_or_add("add");
        }}
        style={{ margin: 10 }}
        variant="outlined"
      >
        <Typography sx={{ flex: 1 }} variant="h4">
          Add services
        </Typography>
      </Button>

      {list_or_add == "add" ? (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            style={{ margin: 5, flex: 1, width: 600 }}
            id="code"
            label="code"
            variant="outlined"
            value={new_code}
            onChange={(e) => set_new_code(e.target.value)}
          />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 600 }}
              id="price"
              // label="price for admin"
              label="double"
              variant="outlined"
              value={new_price}
              onChange={(e) => {
                set_new_price(render_number_onchange(e.target.value));
              }}
            />
            <PriceCurrency
              defaultValue={new_price_curr}
              onChange={(value) => set_new_price_curr(value)}
            />
          </Box>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 600 }}
              id="price"
              // label="price for customer"
              label="single"
              variant="outlined"
              value={new_price_for_cus}
              onChange={(e) => {
                set_new_price_for_cus(render_number_onchange(e.target.value));
              }}
            />

            <PriceCurrency
              defaultValue={new_price_for_cus_curr}
              onChange={(value) => set_new_price_for_cus_curr(value)}
            />
          </Box>
          <Button
            onClick={() => AddService()}
            style={{ margin: 10, width: 100 }}
            variant="outlined"
          >
            Confirm
          </Button>
        </Box>
      ) : null}
      <Button
        onClick={() => {
          set_list_or_add("list");
        }}
        style={{ margin: 10 }}
        variant="outlined"
      >
        <Typography sx={{ flex: 1 }} variant="h4">
          List of services
        </Typography>
      </Button>
      {list_or_add == "list" ? (
        <Box>
          <Box>
            <Box style={{ flexDirection: "row", display: "flex" }}>
              <Button
                disabled={id_service_click == null}
                style={{ margin: 10 }}
                variant="outlined"
                color="error"
                onClick={() => deleteService()}
              >
                Delete service
              </Button>
              <Button
                disabled={id_service_click == null}
                style={{ margin: 10 }}
                variant="outlined"
                onClick={() => saveService()}
              >
                Save service
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <Box
              style={{
                flexWrap: "wrap",
                flexDirection: "column",
                display: "flex",
                flex: 1,
              }}
            >
              {props.data.map((item) => (
                <Card
                  onClick={() => clickService(item)}
                  style={{
                    margin: 20,
                    backgroundColor:
                      id_service_click == item._id ? "#E3F2FD" : "white",
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      services CODE: {item.code}
                    </Typography>
                    <Typography variant="h6">
                      {/* Price for admin: {render_number(item.price)}{" "} */}
                      double: {render_number(item.price)} {item.price_currency}
                    </Typography>
                    <Typography variant="h6">
                      {/* Price for customer:{" "} */}
                      single:
                      {render_number(item.price_for_customer)}{" "}
                      {item.price_for_customer_currency}
                    </Typography>
                    <Typography variant="body2">
                      Number link: {item.attach_acti.length}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>

            {id_service_click == null ? null : (
              <Box
                style={{
                  flexWrap: "wrap",
                  flexDirection: "column",
                  display: "flex",
                  flex: 1,
                }}
              >
                <TextField
                  style={{ margin: 5, flex: 1, width: 600 }}
                  id="code"
                  label="code"
                  variant="outlined"
                  value={id_service_code}
                  onChange={(e) => set_id_service_code(e.target.value)}
                />
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    style={{ margin: 5, flex: 1, maxWidth: 600 }}
                    id="price"
                    // label="price for admin"
                    label="double"
                    variant="outlined"
                    value={id_service_price}
                    onChange={(e) => {
                      set_id_service_price(
                        render_number_onchange(e.target.value)
                      );
                    }}
                  />
                  <PriceCurrency
                    defaultValue={id_service_price_curr}
                    onChange={(value) => set_id_service_price_curr(value)}
                  />
                </Box>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    style={{ margin: 5, flex: 1, maxWidth: 600 }}
                    id="price"
                    // label="price for customer"
                    label="single"
                    variant="outlined"
                    value={id_service_price_for_cus}
                    onChange={(e) => {
                      set_id_service_price_for_cus(
                        render_number_onchange(e.target.value)
                      );
                    }}
                  />

                  <PriceCurrency
                    defaultValue={id_service_price_for_cus_curr}
                    onChange={(value) =>
                      set_id_service_price_for_cus_curr(value)
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box style={{ display: "flex", flexDirection: "row" }}>
            {id_service_click == null ? null : (
              <Box style={{ flex: 1, margin: 5 }}>
                <Typography sx={{ flex: 1 }} variant="h5">
                  List contained
                </Typography>
                <Box style={{ flexDirection: "row", display: "flex" }}>
                  <Button
                    style={{ margin: 10, flex: 1 }}
                    variant="outlined"
                    color="error"
                    onClick={() => RemoveService(rowSelection_contain)}
                  >
                    Remove
                  </Button>
                </Box>

                <DataGrid
                  getRowId={(item) => item._id}
                  rows={list_att}
                  columns={
                    props_type_activ
                      ? COLUMN_ITINERRARY_MINI
                      : COLUMN_ACCOM_MINI
                  }
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                  rowSelectionModel={rowSelection_contain}
                  onRowSelectionModelChange={(e) => set_rowSelection_contain(e)}
                  keepNonExistentRowsSelected
                />
              </Box>
            )}

            {id_service_click == null ? null : (
              <Box style={{ flex: 1, margin: 5 }}>
                <Typography sx={{ flex: 1 }} variant="h5">
                  List not contain
                </Typography>
                <Box style={{ flexDirection: "row", display: "flex" }}>
                  <Button
                    style={{ margin: 10, flex: 1 }}
                    variant="outlined"
                    onClick={() => {
                      AddServiceBtn(rowSelection_not_contain);
                    }}
                  >
                    Add
                  </Button>
                  <TextField
                    style={{ margin: 10, flex: 5 }}
                    id="search"
                    label="search"
                    variant="outlined"
                    value={search_text}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        handle_search("");
                      }
                      set_search_text(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        handle_search(search_text);
                      }
                    }}
                  />
                </Box>

                <DataGrid
                  getRowId={(item) => item._id}
                  rows={id_service_click == null ? [] : data_filter}
                  columns={
                    props_type_activ
                      ? COLUMN_ITINERRARY_MINI
                      : COLUMN_ACCOM_MINI
                  }
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                  rowSelectionModel={rowSelection_not_contain}
                  onRowSelectionModelChange={(e) =>
                    set_rowSelection_not_contain(e)
                  }
                  keepNonExistentRowsSelected
                  checkboxSelection
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
